import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import SliderText from "../components/Home/SliderText";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Testimonials from "../components/Home/Testimonial";
import Games from "../components/Games";
import News from "../components/News.js";
import IMAGES from "../img/image.js";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Home.css";
import { message } from "antd";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [display, setDisplay] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // async function getNoti() {
  //   try {
  //     const res = await axios.get("/api/noti/get-noti");
  //     if (res.data.success) {
  //       setImage(res.data.data[0].image);
  //       setLink(res.data.data[0].link);
  //       setDisplay(res.data.data[0].display);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    getUserData();
    // getNoti();
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowPopup(true);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want quit?";
      localStorage.setItem("giveaway", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("giveaway", "false");
  };

  return (
    <Layout>
      {showPopup && display === "yes" && (
        <div className="popup-container">
          <div>
            <CancelIcon onClick={handleClosePopup} className="icon" />
          </div>
          <Link target="_blank" to={link}>
            <img src={image} alt="popup-img" />
          </Link>
        </div>
      )}
      <HeroSection />
      <SliderText
        text={"ARISATO STORE"}
        direction={"left"}
        bg={"#fff"}
        fs={16}
      />
      <Games title={"Popular Games Top-Up"} />
      {/* <HowItWorks /> */}
    </Layout>
  );
};

export default Home;
